import React, { Component } from "react";
 
class Introduction extends Component {
  render() {
    return (
      <div>
        <h2>Hello World</h2>
        <p>Learning how to create a webapp</p>
 
        <p>A simple React App</p>
      </div>
    );
  }
}
 
export default Introduction;