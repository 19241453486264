import React, { Component } from "react";
 
class Timing extends Component {
  render() {
    return (
      <div>
        <h2>Office Timings</h2>
        <p>08:30-18:00</p>
      </div>
    );
  }
}
 
export default Timing;