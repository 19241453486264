import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <h2>Contact Details</h2>
        <p>Drop a mail at <li>akash.91@wipro.com</li>.
        </p>
      </div>
    );
  }
}
 
export default Contact;